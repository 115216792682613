.campoPessoa:first-child {
    margin-left: 0px !important;
}

.campoPessoa {
    margin: 0px .75em;
}

.campoPessoa svg {
    margin-right: .3em;
}