
.container-login{
    height: 100vh;
    display: grid;
}

.container-login .login-content{
    min-width: 450px;
    max-width: 500px;
    min-height: 450px;
    margin: auto;
}

.container-login .login-content .card-body {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
}

.container-login .login-content .card-body form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.container-login .login-content .card-body button{
    max-width: 200px;
    position: initial;
    border-radius: 6px;
    margin: 0 auto;
}

.logoLogin {
    max-width: 380px;
    max-height: 170px;
    margin: 0 auto;
    display: block;
}

@media only screen and (max-width: 500px) {
    .container-login .login-content{
        width: 100%;
        min-width: auto;
        max-width: auto;
    }
}