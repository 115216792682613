.span-card-novo-atendimento {
  font-size: 18px;
  color: #818e9b;
}
.voltar-icone {
  font-size: 36px;
  color: #818e9b;
}
.voltar-icone:hover {
  cursor: pointer;
}

/* ============== Tela de novo atendimento ============== */
.botao-cancelar-desktop {
  width: 220px;
  margin-top: -40px;
  margin-right: 10px;
}
.anexos {
  width: 50px;
  height: 50px;
  background-color: #f3f3f3;
  border-radius: 10px;
  display: flex;
  margin: 15px 0px;
}
.anexos:hover {
  cursor: pointer;
}
.anexos svg {
  color: #818e9b;
  margin: auto;
}
