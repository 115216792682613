.content-container {
  max-width: 1450px;
  padding-bottom: 2rem;
  position: absolute;
  left: 0;
  zoom: 90%;
}
.header {
  position: fixed;
  z-index: 1;
  width: 80%;
  background-color: #f9f9f9be;
}
.header-container {
  justify-content: flex-end;
  height: 50px;
}
.header-container .col-12 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.notificacao {
  background-color: unset;
  height: 80%;
  width: 60px;
  border-radius: 10px;
  border: unset;
}
.header-icon-notificacao {
  color: #818e9b;
  width: 50%;
  height: 100%;
  margin-left: 5%;
}
.header-icon {
  width: 20px;
  height: 100%;
  color: white;
}
.header-icon-toggle {
  width: 30%;
  height: 100%;
  color: white;
}
hr {
  margin-top: unset;
  margin-bottom: unset;
}
