.nomePessoa {
  font-size: 1.5em;
}

.modalAtendimentoHeader {
  justify-content: space-between;
}

.historicoAtendimento .list-group-item {
  padding: 0.5rem 0.5rem;
  border: none;
  margin: 0.35em 0;
  border-radius: 0.25rem;
}

.wrap {
  text-align: left;
  position: relative;
}

.links {
  display: flex;
  justify-content: center;
  position: relative;
}

.wrap small {
  background-color: white;
  padding: 1px 5px;
  font-weight: bold;
  font-size: 1rem;
}

.wrap:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid rgb(180, 180, 180);
  width: 100%;
  transform: translateY(-50%);
}

.grupo-btn,
.grupo-btn > div {
  display: flex;
  justify-content: flex-end;
}

.grupo-btn > div button {
  margin-top: 0 !important;
  margin-left: 1rem;
}

dt,
dd {
  margin-bottom: 0 !important;
  padding: 0.25rem !important;
}

.divisor {
  width: 98%;
  margin: 25px auto;
  border-color: #abb5ba;
}

.anexo-atendimento:hover {
  color: #263a8a;
  cursor: pointer;
}
