.modal-title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  /* line-height: 50px; */
}
.modal-footer{
    justify-content: center;
}
.actions {
  width: 100%;
  text-align: center !important;
}

.secondaryText {
  color: #abb5ba !important;
}

.subTitulo {
  display: block;
  width: 100%;
  word-wrap: break-word;
}
