.textoBotao {
  font-size: 12px;
}
.tableHead {
  color: #abb5ba !important;
}

/* ===================== TELA NOVO DEPARTAMENTO ===================== */
tfoot {
  border-color: #fff;
}
.divisor-cria-departamento {
  width: 95%;
  margin: 25px auto;
  border-color: #abb5ba;
}
.adiciona-usuario {
  color: #abb5ba;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.remove-usuario {
  border-radius: 5px;
  width: 70%;
  line-height: 25px;
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #BE3F3F;
  border-color: #BE3F3F;
}
.remove-usuario:hover {
  cursor: pointer;
}
.secao-desativa {
  opacity: 0.5;
}

/* ===================== TELA EDIÇÃO DEPARTAMENTO ===================== */
.secondaryText {
  color: #abb5ba !important;
}

.subTitulo {
  display: block;
  width: 100%;
  word-wrap: break-word;
}
