.titulo-secao {
  margin: 20px 0px 10px;
}
h3 {
  font-size: 26px;
}
.card-meus-atendimentos {
  background-color: red;
  height: 100px;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 40px 0px;
}
.texto-secao {
  padding: 10px;
  background-color: blue;
}
.titulo-card {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.subtitulo-card {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.grafico-secao {
  background-color: aqua;
}

.card-estatisticas {
  height: 200px;
  border-radius: 20px;
  background-color: #369eff33;
}
.card-estatisticas:first-child span {
  font-size: 18px;
  color: #8ea3b7;
}
.separator {
  width: 3%;
  border-radius: 10px;
  align-self: center;
  background-color: #006ed3;
}
.numero-card {
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
.numero-card h3 {
  font-weight: bold;
  color: #006ed3;
}
