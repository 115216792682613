body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4 {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.logo {
  width: 100px;
}

.logo img {
  width: 100%;
}

.btn-group-sm > .btn.btn-link,
.btn-sm.btn-link {
  border-radius: 0 !important;
  box-shadow: none;
  padding: 0.05rem 0rem;
}

.btn-group > .btn.btn-link,
.btn.btn-link {
  border-radius: 0 !important;
  box-shadow: none;
  padding: 0.25rem 0.35rem;
}

.styled-form-row {
  align-items: end;
  margin-bottom: 0.7rem;
}

/********************************************************************************
*                                navbar                                        *
********************************************************************************/

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  margin: 0;
  padding: 0.5rem;
  width: 100%;
}

.navbar-light {
  background-color: #f7f7f7 !important;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: bold;
}

/********************************************************************************
*                                  card                                        *
********************************************************************************/

.card {
  border: none;
  background-color: transparent;
}

.card-header {
  margin-top: 50px;
  background-color: transparent;
  padding: 0.75rem 1.25rem 0.75rem 0;
  margin-bottom: 0;
  border-bottom: none;
}

.bg-primary.card-header {
  color: white;
}

.card-header h1 {
  font-size: 2em;
  font-weight: bold;
}

.card-body {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  background-color: white;
}

/********************************************************************************
*                                  table                                        *
********************************************************************************/

table {
  border: none;
}

label {
  color: #818e9b;
}

.table thead th {
  border-top: none;
  color: #263a8a;
}

.table-action-button {
  width: 80px;
  font-size: 10px;
}

/********************************************************************************
*                                breadcrumb                                     *
********************************************************************************/

.breadcrumb {
  padding: 0;
  margin-bottom: 0.2em;
}

.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item a {
  color: #b0b2b5;
  font-weight: bold;
  text-transform: uppercase;
}

.breadcrumb-item a:hover {
  text-decoration: none;
  color: #263a8a;
}

/********************************************************************************
*                                   form                                        *
********************************************************************************/

.form-control {
  border-radius: 0.2em;
}

.nav-tabs {
  background: #f7f7f7;
  text-align: center;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  border: none;
  border-bottom: 4px solid #263a8a;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 4px solid #263a8a;
}

.nav-tabs .nav-link {
  cursor: pointer;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
  border-bottom: 3px solid transparent;
  padding: 0.5em 1.5em;
}

form button.btn.btn-primary,
form button.btn.btn-secondary,
form button.btn.btn-success,
form button.btn.btn-info,
form button.btn.btn-warning,
form button.btn.btn-danger,
.grupo-btn button.btn {
  border-radius: 0.25rem !important;
}

.btn-coment:hover {
  text-decoration: none !important;
  transition: 0.2s;
}

.primary-button {
  background-color: #109cf1;
  border-color: #109cf1;
  color: #fff;
  box-shadow: 0.5px 0.5px 5px #888888;
}
.primary-button:hover {
  background-color: #ebf7ff;
  color: #109cf1;
  border-color: #109cf1;
}
.primary-button:focus {
  background-color: #109cf1;
  border-color: #109cf1;
  color: #fff;
  box-shadow: 2px 4px 8px #888888;
}

.reverse-primary-button {
  background-color: #ffffff;
  color: #2d68a2;
  border-color: #2d68a2;
  box-shadow: 0.5px 0.5px 5px #888888;
}
/* .reverse-primary-button:hover {
  background-color: #2D68A2;
  border-color: #2D68A2;
  color: #fff;
} */
