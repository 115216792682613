.btnMenu {
  height: 2.5em;
  width: 2.5em;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 /20%);
  -webkit-box-shadow: 0 3px 6px 0 rgb(0 0 0 /20%);
  border-radius: 1.25em;
  border: none;
}
.logo {
  width: 70px;
  margin: 1em auto 1.5em;
}
.logoMenu {
  font-size: 32px;
  height: 100%;
  padding: 5px;
  margin: auto 10px auto 0px;
}
.textMenuContent {
  font-size: 14px;
  margin: auto 0;
}
.menuContent {
  padding: 5px;
}
.menu-container {
  background-color: #fff;
}
.menu-container-fixed {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  overflow-x: hidden;
}
.navbar-toggler {
  border-radius: unset;
  background-color: #fff;
}
.side-nav {
  display: flex;
  background-color: #fff !important;
}

.nav-link {
  color: #abb5ba !important;
  margin: 0.2rem 0;
  background-color: #fff;
  border-radius: 15px;
}
.nav-link:hover {
  color: white !important;
  background-color: #3c6deb;
  border-radius: 15px;
}
.active-menu {
  color: white !important;
  background-color: #3c6deb;
  border-radius: 15px;
}
.active-menu .logoMenu {
  background-color: #fff;
  color: #3c6deb;
  border-radius: 50%;
}
.nav-link:hover .logoMenu {
  background-color: #fff;
  color: #3c6deb;
  border-radius: 50%;
}

nav.menu-container .navbar-nav .nav-item a.nav-link:hover {
  background-position: 0;
  color: black;
}

nav.menu-container .navbar-nav .nav-item a.nav-link:hover::before {
  width: 100%;
}
