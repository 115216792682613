.inputFile {
  background-color: #e9ecef;
  overflow: hidden;
  cursor: pointer;
}

.inputFile.dragging,
.inputFile.uploading {
  cursor: not-allowed;
}

.inputFile.dragging {
  background-color: #adcff0;
  color: rgb(8, 8, 77);
}

.inputFile p {
  font-size: 0.9em;
}

.inputFile .text {
  display: block;
  padding: 1.5em 1em;
  margin: 0.5em !important;
  border: 2px dashed #333;
  text-align: center;
}

.inputFile .text svg {
  font-size: 3em;
  margin-bottom: 0.4em;
}

.inputFile input,
.inputFile p {
  line-height: 1em;
  margin-bottom: 0px;
}

.filesList {
  padding: 5px;
  font-size: 0.85em;
}

.filesList svg {
  margin-right: 5px;
}
